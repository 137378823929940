.drawer-notification-main {
  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      padding: 16px;
    }
    .ant-drawer-body {
      &::-webkit-scrollbar {
        width: 6px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: rgb(203, 203, 203); /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(68, 68, 68); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 10px solid rgb(68, 68, 68); /* creates padding around scroll thumb */
      }
    }
  }
}
.searchModal {
  .ant-modal-content {
    padding: 0.5rem 0 0 0;
  }
}
.search-body {
  height: 40vh;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px; /* width of the entire scrollbar */
    margin: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(203, 203, 203); /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(68, 68, 68); /* color of the scroll thumb */

    border-radius: 20px; /* roundness of the scroll thumb */
    border: 10px solid rgb(68, 68, 68); /* creates padding around scroll thumb */
  }
}
.header-logo{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: contain;
}
