@import "~react-image-gallery/styles/scss/image-gallery.scss";
$primary: #ff8d26;
$hoverPrimary: lighten($primary, 25%);
$sideBarBg: #222222;
$borderRadius: 6px;

.gredientEffect {
  background: linear-gradient(120deg,
      $primary,
      $hoverPrimary,
      $primary,
      $hoverPrimary );
  background-size: 500% 100%;
  animation: gradient 38s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.leftHoverConatier {
  a {
    .ant-btn-default:not(:disabled):hover {
      color: $primary;
      background-color: #fff !important;
      border-color: #ffffff !important;
    }
  }
}

.searchItem{
  &:hover{
    color: #000000;
    background-color: $hoverPrimary;
  }
}

.bg-transparent {
  background-color: transparent;
}

.ant-btn-link {
  color: $primary;

  &:hover {
    color: $hoverPrimary !important;
  }
}

.ant-btn-primary {
  box-shadow: none;
  background: $primary;
}

.ant-tooltip-arrow {
  display: none !important;
}

.ant-table {
  border-radius: $borderRadius;
}

.ant-table-body {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: rgb(203, 203, 203);
    /* color of the tracking area */
    border-radius: 20px;
    /* roundness of the scroll thumb */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(68, 68, 68);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 10px solid rgb(68, 68, 68);
    /* creates padding around scroll thumb */
  }
}
.billing-card {
  background-color: #000000;
}
.bg-custom {
  background-color: #141414;
}
.bg-custom-light {
  background-color: #07070773;
}
.bg-kanban {
  background-color: #27293d;
}
table {
  thead {
    tr {
      th {
        background: rgba($primary, 0.3) !important;
      }
    }
  }

  tr:hover {
    background: rgba($primary, 0.08) !important;
  }

  tr:hover td {
    background: transparent !important;
  }
}

.jodit-status-bar {
  min-height: 50px;
}

.jodit-workplace {
  min-height: 36vh !important;

  @media screen and (max-width: 768px) {
    min-height: 52vh !important;
  }
}

// .ant-alert-success .ant-alert-icon{
//   color: $primary;
// }
// .ant-alert-success{
//   background-color: white;
//     border: 1px solid $primary;
// }

a {
  color: $primary;

  &:hover {
    color: $hoverPrimary !important;
  }
}

body::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: grey;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: black;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 10px solid black;
  /* creates padding around scroll thumb */
}

.ant-layout .ant-layout-sider {
  background: $sideBarBg !important;

  @media screen and (max-width: 768px) {
    max-width: 52px !important;
    min-width: 52px !important;
    width: 52px !important;
  }
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: $sideBarBg !important;
}

.sideBarMenuList {
  .ant-menu-dark {
    background: $sideBarBg !important;
  }
}

.ant-layout-sider {
  background: $sideBarBg !important;
}

.ant-layout-header {
  background: $sideBarBg !important;
}

.ant-menu-sub {
  background: $sideBarBg !important;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-black {
  background-color: #092321 !important;
  border: 1px solid #092321 !important;
}

.d-inline {
  display: inline !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-flow: column;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}
.justify-content-start{
  justify-content: start;
}
.align-items-center {
  align-items: center;
}

.h2 {
  font-size: 32px;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.base-container {
  padding: 64px;

  @media screen and (max-width: 767px) {
    padding: 16px;
  }
}

.container-layout {
  padding: 64px 0px;

  @media screen and (max-width: 767px) {
    padding: 16px 0px;
  }
}

.flex-column {
  display: flex;
  flex-flow: column;
}
.gap-2{
  gap: 2rem;

}

.text-black {
  color: #092321 !important;
}

//   .bg-primary {
//     background-color: #ff9327;
//     border: 1px solid #ff9327;
//   }
.text-primary {
  color: $primary;
}

.h-100 {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

.w-100 {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25%;
}

// margin
.m-6 {
  margin: 48px;
}

.m-5 {
  margin: 32px;
}

.m-4 {
  margin: 22px;
}

.m-3 {
  margin: 16px;
}

.m-2 {
  margin: 0.5rem;
}

.m-1 {
  margin: 0.25rem;
}

.m-0 {
  margin: 0px;
}

.mt-6 {
  margin-top: 48px;
}

.mt-5 {
  margin-top: 32px;
}

.mt-4 {
  margin-top: 22px;
}

.mt-3 {
  margin-top: 16px;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-0 {
  margin-top: 0px;
}

.mr-6 {
  margin-right: 48px;
}

.mr-5 {
  margin-right: 32px;
}

.mr-4 {
  margin-right: 22px;
}

.mr-3 {
  margin-right: 16px;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-0 {
  margin-right: 0px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mb-5 {
  margin-bottom: 32px;
}

.mb-4 {
  margin-bottom: 22px;
}

.mb-3 {
  margin-bottom: 16px;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-6 {
  margin-left: 48px;
}

.ml-5 {
  margin-left: 32px;
}

.ml-4 {
  margin-left: 22px;
}

.ml-3 {
  margin-left: 16px;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-0 {
  margin-left: 0px;
}

// padding

.p-6 {
  padding: 48px;
}

.p-5 {
  padding: 32px;
}

.p-4 {
  padding: 22px;
}

.p-3 {
  padding: 16px;
}

.p-2 {
  padding: 0.5rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-0 {
  padding: 0px;
}

.pt-6 {
  padding-top: 48px;
}

.pt-5 {
  padding-top: 32px;
}

.pt-4 {
  padding-top: 22px;
}

.pt-3 {
  padding-top: 16px;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-0 {
  padding-top: 0px;
}

.pr-6 {
  padding-right: 48px !important;
}

.pr-5 {
  padding-right: 32px !important;
}

.pr-4 {
  padding-right: 22px !important;
}

.pr-3 {
  padding-right: 16px !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pb-6 {
  padding-bottom: 48px !important;
}

.pb-5 {
  padding-bottom: 32px !important;
}

.pb-4 {
  padding-bottom: 22px !important;
}

.pb-3 {
  padding-bottom: 16px !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-6 {
  padding-left: 48px !important;
}

.pl-5 {
  padding-left: 32px !important;
}

.pl-4 {
  padding-left: 22px !important;
}

.pl-3 {
  padding-left: 16px !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.text-black {
  color: black;
}

.text-white {
  color: rgb(248, 248, 248);
}

.text-black-light {
  color: rgba(52, 49, 76, 0.54);
}

.text-gray {
  color: gray;
}

.text-danger {
  color: #ee4b4f;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-decoration-overline {
  text-decoration: overline;
}

//font size
.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fs-32 {
  font-size: 32px;
}

.fs-48 {
  font-size: 48px;
}

.lh-12 {
  line-height: 12px;
}

.lh-14 {
  line-height: 14px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

.lh-20 {
  line-height: 20px;
}

.lh-22 {
  line-height: 22px;
}

.lh-24 {
  line-height: 24px;
}

.lh-28 {
  line-height: 28px;
}

.lh-32 {
  line-height: 32px;
}

.lh-48 {
  line-height: 48px;
}

.text-bolder {
  font-weight: 700;
}

.text-bold {
  font-weight: 500;
}

.text-normal {
  font-weight: 400;
}

.text-light {
  font-weight: 300;
}

.text-italic {
  font-style: italic;
}

// display

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-inline-flex {
  display: inline-flex;
}

// float

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

// position

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

// top

.top-0 {
  top: 0px;
}

// right

.right-0 {
  right: 0px;
}

// bottom

.bottom-0 {
  bottom: 0px;
}

// left

.left-0 {
  left: 0px;
}

// z-index

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

// border
.border {
  border: 1px solid #e2e8f0;
}

.border-top {
  border-top: 1px solid #e2e8f0;
}

.border-right {
  border-right: 1px solid #e2e8f0;
}

.border-bottom {
  border-bottom: 1px solid #e2e8f0;
}

.border-left {
  border-left: 1px solid #e2e8f0;
}

// border-black use in Pricing Cards
.border-black {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.border-top-black {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.border-right-black {
  border-right: 1px solid #000000;
}

.border-bottom-black {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.border-left-black {
  border-left: 1px solid #000000;
}

// border radius
.rounded {
  border-radius: $borderRadius;
}
.rounded-top{
  border-radius: $borderRadius $borderRadius 0 0;
}
.rounded-bottom{
  border-radius: 0 0 $borderRadius $borderRadius;
}
.rounded-tl{
  border-radius: $borderRadius 0 0 0;
}
.rounded-tr{
  border-radius: 0 $borderRadius 0 0;
}
.rounded-br{
  border-radius: 0 0 $borderRadius 0;
}
.rounded-bl{
  border-radius: 0 0 0 $borderRadius;
}
.rounded-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-full {
  border-radius: 999px;
}

// round shape

.rounded-50 {
  border-radius: 50%;
}

// shadow

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

// flex

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-no-grow {
  flex-grow: 0;
}

.flex-no-shrink {
  flex-shrink: 0;
}

// justify-content

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

// align-items

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

// align-content

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

// align-self

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

// order

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

// opacity

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

// visibility

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

// overflow

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

// resize

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize-x {
  resize: horizontal;
}

.resize {
  resize: both;
}

// cursor

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

// pointer-events

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

// user-select

.select-none {
  user-select: none;
}

.select-text {
  user-select: text;
}

.select-all {
  user-select: all;
}

.select-auto {
  user-select: auto;
}

// fill

.fill-current {
  fill: currentColor;
}

// stroke

.stroke-current {
  stroke: currentColor;
}

// table-layout

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

// box-decoration-break

.decoration-slice {
  box-decoration-break: slice;
}

.decoration-clone {
  box-decoration-break: clone;
}

// list-style-type

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.list-decimal {
  list-style-type: decimal;
}

// list-style-position

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

// placeholder-color

.placeholder-transparent {
  color: transparent;
}

.placeholder-current {
  color: currentColor;
}

// placeholder-opacity

.placeholder-opacity-0 {
  opacity: 0;
}

.placeholder-opacity-25 {
  opacity: 0.25;
}

.placeholder-opacity-50 {
  opacity: 0.5;
}

.placeholder-opacity-75 {
  opacity: 0.75;
}

.placeholder-opacity-100 {
  opacity: 1;
}

// placeholder-visibility

.placeholder-visible {
  visibility: visible;
}

.placeholder-invisible {
  visibility: hidden;
}

.text-primary-light {
  color: rgba($primary, 0.5);
}

.bg-primary-light {
  background-color: rgba(0, 87, 110, 0.2);
}

.bg-gray {
  background-color: rgb(248, 249, 250);
}

.bg-white {
  background-color: #fff;
}

.default-line-height {
  line-height: 1.5rem;
}

// vertical align 

.vertical-align-top {
  vertical-align: top;
}

@media screen and (min-width: 767px) {
  .d-md-flex {
    display: flex;
  }

  .fs-md-20 {
    font-size: 20px;
  }

  .fs-md-18 {
    font-size: 18px;
  }

  .mt-md-0 {
    margin-top: 0px;
  }

  .ml-md-2 {
    margin-left: 0.5rem;
  }

  .pl-md-3 {
    padding-left: 16px !important;
  }

  .pl-md-5 {
    padding-left: 32px !important;
  }

  .pr-md-4 {
    padding-right: 22px !important;
  }

  .p-md-0 {
    padding: 0px !important;
  }

  .pb-md-6 {
    padding-bottom: 48px !important;
  }

  .pb-md-5 {
    padding-bottom: 32px !important;
  }

  .pb-md-4 {
    padding-bottom: 22px !important;
  }

  .pb-md-3 {
    padding-bottom: 16px !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0px !important;
  }
}

// slick silder arrow css
.slick-arrow {
  z-index: 1 !important;
  width: 40px !important;
  height: 40px !important;
}

.slick-prev:before {
  color: black !important;
}

.slick-next:before {
  color: black !important;
}

@media screen and (max-width: 768px) {
  .d-sm-none {
    display: none;
  }

  .pl-sm-3 {
    padding-left: 16px !important;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-1 {
    order: 1;
  }

  .p-sm-0 {
    padding: 0px;
  }
}

.ant-tag-checkable {
  color: black !important;
  :hover {
    color: black !important;
  }
}

.ant-tag-checkable-checked {
  background-color: $primary !important;
  color: white !important;
  :hover {
    color: white !important;
  }
}
.fc-toolbar-title {
  color: $primary !important;
}
.ant-progress-bg{
    background-color: $primary !important;
}
.ant-progress-circle-path {
  background-color: $primary !important;
}