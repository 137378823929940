.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.site-layout {
  .ant-layout-content {
    overflow-y: scroll !important;

    &::-webkit-scrollbar {
      width: 6px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: rgb(203, 203, 203); /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(68, 68, 68); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 10px solid rgb(68, 68, 68); /* creates padding around scroll thumb */
    }
    @media screen and (max-width: 767px) {
      padding: 16px !important;
    }
  }
}
.mainLayout {
  .ant-layout {
    position: relative;
    .ant-layout-sider {
      position: inherit;
    }
  }
}
.sideBarWithCollapse {
  .ant-layout {
    .ant-layout-sider {
      @media screen and (max-width: 767px) {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        z-index: 9999 !important;
        position: fixed !important;
      }
    }
  }
}
.float-btn-setting {
  width: 60px !important;
  bottom: 400px !important;
  right: -0.4%;
   
  
  & .ant-float-btn-body {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-right: 6px;
    background-color: #005847;
    & .ant-float-btn-content {
      padding: 2px 12px !important;
      & svg {
        color: white !important;
      }
    }
    & svg.feather.feather-settings {
      transform: rotate(45deg);
      animation: rotate 4s linear infinite;

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}
body[dir="rtl"] .float-btn-setting {
  right: unset;
  left: -0.2%;
  & .ant-float-btn-body {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-right: 1px;
    padding-left: 3px;
    background-color: #005847;
    & .ant-float-btn-content {
      padding: 2px 12px !important;
      & svg {
        color: white !important;
      }
    }
    & svg.feather.feather-settings {
      transform: rotate(45deg);
      animation: rotate 4s linear infinite;

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}
.cssbuttons-io-button {
  background: #A370F0;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
}

.cssbuttons-io-button .icon {
  background: white;
  position: absolute;
  padding: 4.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.8em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
  // right: 0.3em;
  left: 0;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}
